import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import '../styles/App.css'
import { AppProps } from './AppProps'
import TopStocks from './TopStocks'
import { Providers } from '@ig-caa/mfe-infrastructure'

export function App (props: AppProps): JSX.Element {
  const { theme, sessionData: { clientLocale, webSiteId, igCompany } } = props
  const localeLoader = async (locale: string) => await import(`../locales/${locale}.json`)
  const supportedLanguages = ['en-GB', 'de-DE', 'fr-FR', 'it-IT', 'nl-NL', 'no-NO', 'pt-PT', 'sv-SE', 'es-ES', 'en-ZA', 'zh-CN', 'zh-TW']

  return (
    <Providers
      appName='top-stocks'
      clientLocale={clientLocale}
      igCompany={igCompany}
      localeLoader={localeLoader}
      supportedLanguages={supportedLanguages}
      theme={theme}
      webSiteId={webSiteId}
    >
      <div className={'app-caa-top-stocks'} data-testid='app-caa-top-stocks' style={{ backgroundColor: props.backgroundColour || '' }}>
        <TopStocks {...props}/>
      </div>
    </Providers>
  )
}

const lifecycles = singleSpaReact({ React, ReactDOM, rootComponent: App })
export const { bootstrap, mount, unmount, update } = lifecycles
export default lifecycles
