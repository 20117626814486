export default function currencyFormatter(locale: string, currency: string, value: number | null, precision: number): string {
  if (value === null) {
    return '—'
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    notation: 'compact'
  }).format(Math.abs(+value))
}

export function fundamentalsCurrencyFormatter(locale: string, currency: string | null, value: number | null, precision: number, unit: string, type: string): string {
  if (value === null) {
    return '—'
  }

  value = unit === 'M' ? value * 1000000 : unit === 'B' ? value * 1000000000 : value

  if (type !== 'C') {
     return unit === 'P' ? value.toFixed(3) + '%' : String(value.toFixed(3))
  }

  if (currency === null) {
    return '-'
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    notation: 'compact'
  }).format(Math.abs(+value))
}
