import styled, { useTheme } from 'styled-components'
import { TableProps, TopSmartScoreStocks } from './AppProps'
import { currencyFormatter } from '@ig-caa/utils/'
import { useQuery } from '@tanstack/react-query'
import fetchTopSmartScoreStocks from '../utils/fetchTopSmartScoreStocks'
import { Spinner } from 'ig-phoenix'
import FilterButton from './FilterButton'
import FilterCards from './FilterCards'
import topSmartScoreStocksFilterer from '../utils/topSmartScoreStocksFilterer'
import { useEffect, useRef, useState } from 'react'
import { MenuArrowDown, MenuArrowUp } from '@ig-caa/media'

interface MarketState {
  [key: string]: boolean
}

export default function TopSmartScoreStocksTable (props: TableProps) {
  const { theme, env, cst, locale, miniView, toggleMiniView, initialWidth } = props
  const phoenixTheme = useTheme()
  /* istanbul ignore next */
  const bgColour = (miniView || theme === 'Light') ? phoenixTheme.color.background.primarySurface.value : phoenixTheme.color.background.primary.value
  const circleBgColour = phoenixTheme.color.text.long.value
  const circleTextColour = phoenixTheme.color.text.inverted.value

  const { isLoading, isError, data } = useQuery<TopSmartScoreStocks[], any>({
    queryKey: ['topSmartScoreStocks'],
    queryFn: async () => await fetchTopSmartScoreStocks(env, cst),
    refetchOnWindowFocus: false
  })

  const [showDetails, setShowDetails] = useState<MarketState>({})
  const handleToggleDetails = (market: string) => {
    setShowDetails((prevState) => ({
      ...prevState,
      [market]: !prevState[market] || false
    }))
  }

  const { sectors, handleSectorFilter, showSector, sectorsToBeShown, filteredStocks } = topSmartScoreStocksFilterer(data)
  const tableCard = useRef(null)
  const [tableWidth, setTableWidth] = useState(initialWidth ?? 925)

  useEffect(() => {
    const element = tableCard.current
    if (!element) return

    let animationFrameId: number | null = null

    /* istanbul ignore next */
    const handleResize = (entries: ResizeObserverEntry[]) => {
      const newWidth = entries[0].contentRect.width

      // Use requestAnimationFrame to debounce updates
      if (animationFrameId) cancelAnimationFrame(animationFrameId)

      animationFrameId = requestAnimationFrame(() => {
        setTableWidth((prevWidth) => {
          if (prevWidth !== newWidth) {
            return newWidth
          }
          return prevWidth
        })
      })
    }

    const resizeObserver = new ResizeObserver(handleResize)
    resizeObserver.observe(element)

    // Clean up the observer on component unmount
    return () => {
      resizeObserver.disconnect()
    }
  }, [tableCard.current])

  if (isLoading) {
    return <div className="top-smart-score-stocks-loading" data-testid='isLoading'><Spinner radius='40px'/></div>
  }

  if (isError) {
    return (
      <>
        <ErrorText data-testid="top-smart-score-stocks-error">We&apos;re not able to load this data right now.<br/>Please
          come back later.</ErrorText>
      </>
    )
  }

  const tableContent = (dataSubset?: TopSmartScoreStocks[]) => (
    dataSubset?.map(m => (
      <>
        <Trow showBorder={showDetails[m.ticker]} width={tableWidth} key={m.ticker}>
          <TDMarket>
            <span style={{ fontWeight: 500 }}>{m.company}</span>
            <span>{m.ticker}</span>
            {(showDetails[m.ticker]) && (tableWidth < 405) && <span>{m.sectorName}</span>}
          </TDMarket>
          {(tableWidth > 490) &&
            <TDMarketCap>{currencyFormatter(locale, m.marketCapCurrencyCode, m.marketCap, 2)}</TDMarketCap>}
          {(tableWidth > 617) && <TDChange
            change={m.yearlyGain}>{m.yearlyGain > 0 ? `+${m.yearlyGain.toFixed(2)}` : m.yearlyGain.toFixed(2)}%</TDChange>}
          {(tableWidth > 405) && <TD>{m.sectorName}</TD>}
          {(tableWidth > 707) && <TD>{new Date(m.topScoreSince).toLocaleDateString(locale)}</TD>}
          <TDSmartScore><SmallCircle width={tableWidth} bgColor={circleBgColour}
            textColor={circleTextColour}>{m.smartScore}</SmallCircle></TDSmartScore>
          {(tableWidth < 707) && <Arrow data-testid={`${m.ticker}-arrow`} onClick={() => {
            handleToggleDetails(m.ticker)
          }}>
            {!showDetails[m.ticker] && <MenuArrowDown />}
            {showDetails[m.ticker] && <MenuArrowUp/>}
          </Arrow>}
        </Trow>
        {(showDetails[m.ticker]) && (tableWidth < 707) &&
          <DropDownTR>
            <DropDownTD>
              {(tableWidth < 490) && <DropDownDiv>
                <DropDownHeadings>Market cap</DropDownHeadings>
                <span>{currencyFormatter(locale, m.marketCapCurrencyCode, m.marketCap, 2)}</span>
              </DropDownDiv>}
              {(tableWidth < 617) && <DropDownDiv>
                <DropDownHeadings>Yearly gain</DropDownHeadings>
                <YearlyGainSpan change={m.yearlyGain}>{m.yearlyGain > 0 ? `+${m.yearlyGain.toFixed(2)}` : m.yearlyGain.toFixed(2)}%</YearlyGainSpan>
              </DropDownDiv>}
              <DropDownDiv>
                <DropDownHeadings>Top score since</DropDownHeadings>
                <span>{new Date(m.topScoreSince).toLocaleDateString(locale)}</span>
              </DropDownDiv>
            </DropDownTD>
          </DropDownTR>}
      </>
    ))
  )

  return (
    <>
      {!miniView && <>
        <FilterButton group='Sector' categories={sectors} handleFilter={handleSectorFilter}
          selectedStates={showSector}/>
        <FilterCards categoriesToBeShown={sectorsToBeShown} handleCategoryFilter={handleSectorFilter}/>
      </>}
      <TableContainer
        data-testid='top-smart-score-table-container'
        rows={miniView ? 3 : filteredStocks?.length as number}
        miniView={miniView}
        bgColor={bgColour}
        ref={tableCard}>
        <table data-testid='table'>
          <TableHeadRow bgColor={bgColour} width={tableWidth} data-testid='header-row'>
            <THMarket>MARKET</THMarket>
            {(tableWidth > 490) && <TH>MARKET CAP</TH>}
            {(tableWidth > 617) && <TH>YEARLY GAIN</TH>}
            {(tableWidth > 405) && <THWideColumn>SECTOR</THWideColumn>}
            {(tableWidth > 707) && <THWideColumn>TOP SCORE SINCE</THWideColumn>}
            <TH>SMART SCORE</TH>
            {(tableWidth < 707) && <THArrow></THArrow>}
          </TableHeadRow>
          {tableContent(miniView ? data.slice(0, 3) : filteredStocks)}
        </table>
        {miniView && <ViewMore
          data-testid='top-smart-score-stocks-view-more-link'
          onClick={() => toggleMiniView()}
        >
          View more
        </ViewMore>
        }
      </TableContainer>
    </>
  )
}

const TableContainer = styled.div<{
  rows: number
  miniView: boolean
  bgColor: string
}>`
  flex-grow: 1;
  display: flex;
  min-height: ${({ miniView }) => miniView ? '' : '190px'};
  flex-direction: column;
  padding: ${({ miniView }) => miniView ? '16px' : '0 16px 16px 0'};
  height: ${({ rows }) => rows < 14 ? '' : '584px'};
  border-radius: ${({ miniView }) => miniView ? '4px' : ''};
  box-shadow: ${({ miniView }) => miniView ? '0px 4px 8px rgba(38, 38, 41, 0.16)' : ''};
  background-color: ${({ bgColor }) => bgColor};
`
const TableHeadRow = styled.tr<{
  width: number
  bgColor: string
}>`
  display: flex;
  padding-bottom: 8px;
  position: sticky;
  top: 0;
  z-index: 10;
  gap: ${({ width }) => width > 808 ? '28.6px' : '10px'};
  background-color: ${({ bgColor }) => bgColor};
`

const TH = styled.th`
  color: ${({ theme }) => theme.color.text.tertiary.value};
  font-size: 11px;
  font-weight: normal;
  text-align: right;
  max-width: 81px;
  width: 100%;
`

const THWideColumn = styled.th`
  color: ${({ theme }) => theme.color.text.tertiary.value};
  font-size: 11px;
  font-weight: normal;
  text-align: right;
  max-width: 104px;
  width: 100%;
`

const THMarket = styled.th`
  color: ${({ theme }) => theme.color.text.tertiary.value};
  font-size: 11px;
  font-weight: normal;
  text-align: left;
  max-width: 300px;
  min-width: 180px;
  width: 100%;
`

const Trow = styled.tr<{
  width: number
  showBorder: boolean
}>`
  display: flex;
  margin-bottom: 4px;
  padding-bottom: 4px;
  font-size: 13px;
  position: relative;
  align-items: center;
  border-bottom:${({ showBorder, theme }) => !showBorder ? `1px solid ${theme.color.separator.primary.background.value}` : ''};
  gap: ${({ width }) => width > 808 ? '28.6px' : '10px'};
`
const TD = styled.td`
  text-align: right;
  max-width: 105px;
  width: 100%;
`

const TDMarketCap = styled.td`
  text-align: right;
  max-width: 80px;
  width: 100%;
`

const TDSmartScore = styled.td`
  text-align: right;
  display: flex;
  justify-content: center;
  max-width: 80px;
  width: 100%;
`

const TDChange = styled.td<{
  change?: number
}>`
  text-align: right;
  color: ${({
    theme,
    change
  }) => change && change > 0 ? theme.color.text.positiveMarketMovement.value : theme.color.text.negativeMarketMovement.value};
  font-weight: 500;
  max-width: 80px;
  width: 100%;
`

const TDMarket = styled.td`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  min-width: 180px;
  width: 100%;
`

const SmallCircle = styled.div<{
  bgColor: string
  textColor: string
  width: number
}>`
  max-width: ${({ width }) => width > 385 ? '24px' : '32px'};
  width: ${({ width }) => width > 385 ? '24px' : '32px'};
  height: ${({ width }) => width > 385 ? '24px' : '32px'};
  line-height: ${({ width }) => width > 385 ? '24px' : '32px'};
  flex-grow: 1;
  border-radius: 50%;
  color: ${({ textColor }) => textColor};
  background-color: ${({ bgColor }) => bgColor};
  text-align: center;
  font-size: ${({ width }) => width > 385 ? '12px' : '16px'};
  font-weight: bold;
  position: relative;
  overflow: hidden;
`

const ErrorText = styled.div`
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  color: ${({ theme }) => theme.color.text.secondary.value};
  text-align: center;
  line-height: 16px;
`

const ViewMore = styled.a`
  font-weight: 400;
  font-size: 11px;
  margin-top: 8px;
  color: ${({ theme }) => theme.color.text.link.value};
  cursor: pointer;
  margin-left: auto;

  &:hover {
    text-decoration: underline;
  }
`

const Arrow = styled.div`
  width: 10px;
  height: 10px;
  display: flex;
  cursor: pointer;
`

const THArrow = styled.th`
  width: 10px
`

const DropDownTR = styled.tr`
  display: flex;
  margin-bottom: 4px;
  padding-bottom: 4px;
  font-size: 13px;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.color.separator.primary.background.value};
`

const DropDownTD = styled.td`
  width: 100%;
`

const DropDownHeadings = styled.span`
  color: ${({ theme }) => theme.color.text.tertiary.value};
  font-size: 11px;
  font-weight: normal;
`

const DropDownDiv = styled.div`
  height: 20px;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
`

const YearlyGainSpan = styled.span<{
  change?: number
}>`
  color: ${({
    theme,
    change
  }) => change && change > 0 ? theme.color.text.positiveMarketMovement.value : theme.color.text.negativeMarketMovement.value};
 `
