import { ENVIRONMENTS, TopAnalystStocksData } from '../components/AppProps'

const ENDPOINTS = {
  prod: 'https://tipranks-api.ig.com/tipranks/api/top-rated/all',
  test: 'https://net-tipranks-api.ig.com/tipranks/api/top-rated/all',
  uat: 'https://web-tipranks-api.ig.com/tipranks/api/top-rated/all',
  demo: 'https://demo-tipranks-api.ig.com/tipranks/api/top-rated/all',
  local: 'https://net-tipranks-api.ig.com/tipranks/api/top-rated/all'
}

export default async function fetchTopAnalystStocks (env: keyof typeof ENVIRONMENTS, cst: string): Promise<TopAnalystStocksData[]> {
  try {
    const data = await fetch(`${ENDPOINTS[env]}`, {
      method: 'GET',
      headers: {
        cst
      }
    }).then(async res => {
      if (!res.ok) throw new Error()
      return await res.json()
    })
    return data
  } catch (error) {
    throw new Error('Data fetching error')
  }
}
