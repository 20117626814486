import { useQuery } from '@tanstack/react-query'
import { Spinner } from 'ig-phoenix'
import styled, { useTheme } from 'styled-components'
import fetchTopAnalystStocks from '../utils/fetchTopAnalystStocks'
import { TableProps, TopAnalystStocksData } from './AppProps'
import FilterButton from './FilterButton'
import { currencyFormatter } from '@ig-caa/utils'
import FilterCards from './FilterCards'
import topAnalystStocksFilterer from '../utils/topAnalystStocksFilterer'

export default function TopAnalystStocks (props: TableProps) {
  const { theme, env, cst, locale, miniView, toggleMiniView } = props
  const phoenixTheme = useTheme()
  /* istanbul ignore next */
  const bgColour = (miniView || theme === 'Light') ? phoenixTheme.color.background.primarySurface.value : phoenixTheme.color.background.primary.value
  const { isLoading, isError, data } = useQuery<TopAnalystStocksData[], any>({
    queryKey: ['topAnalystStocks'],
    queryFn: async () => await fetchTopAnalystStocks(env, cst),
    refetchOnWindowFocus: false
  })

  const { sectors, handleSectorFilter, showSector, sectorsToBeShown, filteredStocks } = topAnalystStocksFilterer(data)

  if (isLoading) {
    return <div className="top-stocks-loading" data-testid='isLoading'><Spinner radius='40px' /></div>
  }

  if (isError) {
    return (
      <>
        <ErrorText data-testid="analyst-stocks-error">We&apos;re not able to load this data right now.<br/>Please come back later.</ErrorText>
      </>
    )
  }

  const tableContent = (dataSubset?: TopAnalystStocksData[]) => (
    dataSubset?.map(m => (
      <Trow key={m.ticker}>
        <TDMarket>
          <span style={{ fontWeight: 500 }}>{m.companyName}</span>
          <span>{m.ticker}</span>
        </TDMarket>
        <TD>{m.consensus}</TD>
        <TD>{new Date(m.lastRatingDate).toLocaleDateString(locale)}</TD>
        <TD data-testid={`${m.ticker}-${m.sector}`}>{m.sector}</TD>
        <TD>{currencyFormatter(locale, 'USD', parseFloat(m.priceTarget), 2)}</TD>
      </Trow>
    ))
  )

  return (
    <>
      {!miniView && <>
        <FilterButton group='Sector' categories={sectors} handleFilter={handleSectorFilter} selectedStates={showSector}/>
        <FilterCards categoriesToBeShown={sectorsToBeShown} handleCategoryFilter={handleSectorFilter}/>
      </>}
      <TableContainer data-testid='top-analyst-stocks-table-container' rows={miniView ? 3 : filteredStocks?.length as number} miniView={miniView} bgColor={bgColour}>
        <table data-testid='table'>
          <TableHeadRow data-testid='header-row' bgColor={bgColour}>
            {['MARKET', 'STOCK RATING', 'LAST RATING', 'SECTOR', 'PRICE TARGET'].map(h => <TH key={h}>{h}</TH>)}
          </TableHeadRow>
          {tableContent(miniView ? data.slice(0, 3) : filteredStocks)}
        </table>
        {miniView && <ViewMore
          data-testid='top-analyst-stocks-view-more-link'
          onClick={ () => toggleMiniView() }
        >
            View more
        </ViewMore>
        }
      </TableContainer>
    </>
  )
}

const ViewMore = styled.a`
  font-weight: 400;
  font-size: 11px;
  margin-top: 8px;
  color: ${({ theme }) => theme.color.text.link.value};
  cursor: pointer;
  margin-left: auto;
  &:hover {
    text-decoration: underline;
  }
`

const ErrorText = styled.div`
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  color: ${({ theme }) => theme.color.text.secondary.value};
  text-align: center;
  line-height: 16px;
`

const TableContainer = styled.div<{
  rows: number
  miniView: boolean
  bgColor: string
}>`
  flex-grow: 1;
  min-height: ${({ miniView }) => miniView ? '' : '190px'};
  display: flex;
  flex-direction: column;
  padding: ${({ miniView }) => miniView ? '16px' : '0 16px 16px 0'};
  height: ${({ rows }) => rows < 8 ? '' : '340px'};
  overflow-y: ${({ miniView }) => miniView ? '' : 'scroll'};
  border-radius: ${({ miniView }) => miniView ? '4px' : ''};
  box-shadow: ${({ miniView }) => miniView ? '0px 4px 8px rgba(38, 38, 41, 0.16)' : ''};
  background-color: ${({ bgColor }) => bgColor};
`
const TableHeadRow = styled.tr<{
  bgColor: string
}>`
  display: flex;
  background-color: ${({ bgColor }) => bgColor};
  padding-bottom: 8px;
  position: sticky;
  top: 0;
  z-index: 10;
`

const TH = styled.th`
  color: ${({ theme }) => theme.color.text.tertiary.value};
  padding-left: 4px;
  font-size: 11px;
  width: 100%;
  font-weight: normal;
  text-align: right;
  &:first-child {
    text-align: left;
    padding-left: 0;
  }
`
const Trow = styled.tr`
  display: flex;
  margin-bottom: 4px;
  padding-bottom: 4px;
  font-size: 13px;
  position: relative;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.separator.primary.background.value};
`
const TD = styled.td`
  width: 100%;
  padding-left: 4px;
  text-align: right;
  &:nth-child(2) {
    color: ${({ theme }) => theme.color.text.long.value};
    font-weight: 500;
    width: 70%
  }
  &:nth-child(5) {
    color: ${({ theme }) => theme.color.text.positiveAmount.value};
    font-weight: 500;
  }
`

const TDMarket = styled.td`
  display: flex;
  flex-direction: column;
  width: 130%;
`
