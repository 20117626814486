export const enum Theme {
  Light = 'Light',
  Dark = 'Dark'
}

export enum ENVIRONMENTS {
  test = 'test',
  uat = 'uat',
  demo = 'demo',
  prod = 'prod'
}

export interface AppProps {
  theme: Theme
  cst: string
  sessionData: {
    clientLocale: string
    currentAccountId: string
    liveClientId: string
    webSiteId: string
    igCompany: string
  }
  env: ENVIRONMENTS
  backgroundColour?: string
}

export interface TableProps {
  locale: string
  cst: string
  env: ENVIRONMENTS
  theme: Theme
  miniView: boolean
  toggleMiniView: Function
  initialWidth?: number
}

export interface TopAnalystStocksData {
  ticker: string
  companyName: string
  consensus: string
  sector: string
  priceTarget: string
  lastRatingDate: string
}

export interface SectorConsensusData {
  sector: string
  strongBuy: number
  moderateBuy: number
  neutral: number
  moderateSell: number
  strongSell: number
  order: number
}

export interface TopSmartScoreStocks {
  ticker: string
  company: string
  marketCap: number
  marketCapCurrencyCode: string
  smartScore: number
  yearlyGain: number
  topScoreSince: string
  sectorName: string
}

export interface SectorState {
  [key: string]: boolean
}

export const VIEW = {
  HOME: 'HOME',
  TOP_SMART_SCORE: 'TOP_SMART_SCORE',
  TOP_ANALYST_STOCKS: 'TOP_ANALYST_STOCKS'
} as const

export type View = typeof VIEW[keyof typeof VIEW]
