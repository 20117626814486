import { CloseSign } from '@ig-caa/media'
import styled from 'styled-components'

interface FilterCardProps {
  categoriesToBeShown: string[]
  handleCategoryFilter: (category: string) => void
}

export default function FilterCards (props: FilterCardProps) {
  const { categoriesToBeShown, handleCategoryFilter } = props

  return (
    <FilterContainer>
      {categoriesToBeShown.map(category =>
        <FilterCard data-testid={`${category}-card`} key={category}>
          <FilterCardText>
            {category}
          </FilterCardText>
          <div data-testid={`${category}-card-close`} onClick={() => handleCategoryFilter(category)} style={{ cursor: 'pointer' }}><CloseSign /></div>
        </FilterCard>)}
    </FilterContainer>
  )
}

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  flex-wrap: wrap;
`

const FilterCard = styled.div`
  height: 16px;
  padding: 4px 8px 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-wrap: no-wrap;
  gap: 8px;
  margin: 0px 8px 4px 0px;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.color.separator.secondary.background.value};
  box-sizing: content-box;
  background: ${({ theme }) => theme.color.checkbox.disabled.background.value};
`

const FilterCardText = styled.div`
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  line-height: ${({ theme }) => theme.size.body.small.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.body.weight.value};
  color: ${({ theme }) => theme.color.text.secondary.value};
`
