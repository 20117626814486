import { useEffect, useState } from 'react'
import { SectorState, TopSmartScoreStocks } from '../components/AppProps'

export default function topSmartScoreStocksFilterer (data: TopSmartScoreStocks[] | undefined) {
  const sectors = Array.from(new Set(data?.map(m => m.sectorName)))

  const [filteredStocks, setFilteredStocks] = useState<TopSmartScoreStocks[]>()
  const [showSector] = useState<SectorState>({})

  useEffect(() => {
    setFilteredStocks(data)
  }, [data])

  const handleSectorFilter = (category: string) => {
    showSector[category] = !showSector[category]
    const filteredData = data?.filter(stock => showSector[stock.sectorName])
    setFilteredStocks(filteredData?.length === 0 ? data : filteredData)
  }

  const sectorsToBeShown = data === filteredStocks ? [] : Array.from(new Set(filteredStocks?.map(m => m.sectorName)))

  return { sectors, handleSectorFilter, showSector, sectorsToBeShown, filteredStocks }
}
