import { AppProps, VIEW, View } from './AppProps'
import styled from 'styled-components'
import { TipRankLogo } from '@ig-caa/media'
import TopSmartScoreStocksTable from './TopSmartScoreStocksTable'
import TopAnalystStocks from './TopAnalystStocks'
import Disclaimer from './Disclaimer'

interface HomeProps extends AppProps {
  setView: (view: View) => void
}

export default function Home (props: HomeProps) {
  const { sessionData: { clientLocale }, cst, env, theme } = props

  return (
    <HomeContainer data-testid='home-container' >
      <Heading>
        <span>
              Make data-driven investment decisions with TipRanks tools. Conduct comprehensive stock research.
          <br/>
              Find new investment ideas. Analyse your portfolio. Follow the best performing Wall Street experts.
        </span>
        <LogoSpan>
          <TipRankLogo/>
        </LogoSpan>
      </Heading>
      <AppContainer>
        <AppHeader>{'Top Smart Score Stocks'}</AppHeader>
        <TopSmartScoreStocksTable data-testid='top-smart-score-mini-view' locale={clientLocale} cst={cst} env={env}
          theme={theme} miniView={true}
          toggleMiniView={() => props.setView(VIEW.TOP_SMART_SCORE)}/>
      </AppContainer>
      <AppContainer>
        <AppHeader>{'Analyst Top Stocks'}</AppHeader>
        <TopAnalystStocks data-testid='top-analyst-stocks-mini-view' locale={clientLocale} cst={cst} env={env}
          theme={theme} miniView={true} toggleMiniView={() => props.setView(VIEW.TOP_ANALYST_STOCKS)}/>
      </AppContainer>
      <Disclaimer lightMode={props.theme === 'Light'} appView={'Home'}/>
    </HomeContainer>
  )
}

const Heading = styled.div`
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  font-weight: ${({ theme }) => theme.font.body.weight.value};
  line-height: ${({ theme }) => theme.size.body.small.lineHeight.value};
  color: ${({ theme }) => theme.color.text.primary.value};
  display: flex;
  gap: 11px;
  justify-content: space-between;
  padding: 16px 0;
`

const LogoSpan = styled.div`
  float: right;
  width: 56px;
  height: 34px;
  align-self: center;
`

const HomeContainer = styled.div`
  padding: 0 16px;
  max-width: 925px;
  min-width: 416px;
  color: ${({ theme }) => theme.color.text.primary.value};
  height: 100%;
`

const AppHeader = styled.span`
  color: ${({ theme }) => theme.color.text.secondary.value};
  font-size: ${({ theme }) => theme.size.caption.medium.text.value};
  letter-spacing: 2px;
  text-transform: uppercase;
  padding-bottom: 8px;
`

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`
