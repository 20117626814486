import { useTheme } from 'styled-components'
import React from 'react'

export default function MenuArrowDown (): JSX.Element {
  const theme = useTheme()
  const svgColour = theme.color.icons.secondary.value
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill={svgColour} xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.4152 7.26545C5.36067 7.31851 5.29591 7.36061 5.22463 7.38933C5.15335 7.41805 5.07694 7.43283 4.99978 7.43283C4.92262 7.43283 4.84621 7.41805 4.77493 7.38933C4.70365 7.36061 4.63889 7.31851 4.58436 7.26545L0.177755 2.9806C0.121986 2.92784 0.077556 2.86484 0.047044 2.79526C0.016532 2.72568 0.000545996 2.6509 1.37451e-05 2.57526C-0.000518506 2.49963 0.0144136 2.42464 0.0439435 2.35466C0.0734734 2.28468 0.117013 2.2211 0.172034 2.1676C0.227054 2.11411 0.292461 2.07177 0.364456 2.04304C0.436451 2.01431 0.513601 1.99977 0.591426 2.00026C0.669251 2.00075 0.746201 2.01626 0.817808 2.04588C0.889416 2.07551 0.954255 2.11867 1.00856 2.17284L4.99975 6.05371L8.99094 2.17284C9.04522 2.11857 9.11007 2.07532 9.1817 2.04561C9.25333 2.01591 9.33033 2.00034 9.40821 1.99981C9.48609 1.99928 9.5633 2.0138 9.63536 2.04252C9.70742 2.07125 9.77288 2.11361 9.82794 2.16714C9.883 2.22067 9.92657 2.2843 9.9561 2.35434C9.98564 2.42438 10.0006 2.49943 9.99998 2.57512C9.99941 2.65081 9.98336 2.72563 9.95278 2.79524C9.92219 2.86485 9.87767 2.92786 9.8218 2.9806L5.4152 7.26545Z" fill="#5A5E5F"/>
    </svg>
  )
}
