import { useTheme } from 'styled-components'
import React from 'react'

export default function CloseSign (): JSX.Element {
  const theme = useTheme()
  const svgColour = theme.color.checkbox.active.background.value
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill={svgColour} stroke={svgColour}/>
    </svg>
  )
}