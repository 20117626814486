import { useEffect, useState } from 'react'
import { SectorState, TopAnalystStocksData } from '../components/AppProps'

export default function topAnalystStocksFilterer (data: TopAnalystStocksData[] | undefined) {
  const sectors = Array.from(new Set(data?.map(m => m.sector)))

  const [filteredStocks, setFilteredStocks] = useState<TopAnalystStocksData[]>()
  const [showSector] = useState<SectorState>({})

  useEffect(() => {
    setFilteredStocks(data)
  }, [data])

  const handleSectorFilter = (category: string) => {
    showSector[category] = !showSector[category]
    const filteredData = data?.filter(stock => showSector[stock.sector])
    setFilteredStocks(filteredData?.length === 0 ? data : filteredData)
  }

  const sectorsToBeShown = data === filteredStocks ? [] : Array.from(new Set(filteredStocks?.map(m => m.sector)))

  return { sectors, handleSectorFilter, showSector, sectorsToBeShown, filteredStocks }
}
