import { useState } from 'react'
import Home from './Home'
import styled from 'styled-components'
import { Link } from 'ig-phoenix'
import { ArrowLeft } from '@ig-caa/media'
import { AppProps, VIEW, View } from './AppProps'
import TopSmartScoreStocksTable from './TopSmartScoreStocksTable'
import TopAnalystStocks from './TopAnalystStocks'
import Disclaimer from './Disclaimer'
import AnalystConsensusBySector from './AnalystConsensusBySector'

export default function TopStocks (props: AppProps) {
  const [view, setView] = useState<View>(VIEW.HOME)

  switch (view) {
    case VIEW.HOME:
      return <Home {...props} setView={setView} />
    case VIEW.TOP_SMART_SCORE:
      return (
        <TopNav setView={setView} data-testid='top-smart-score-full-view'>
          <Heading style={{ padding: '16px 0px 16px 0px' }}>
            Top Smart Score Stocks
          </Heading>
          <TopSmartScoreStocksTable
            toggleMiniView={/* istanbul ignore next */ () => {
            }}
            locale={props.sessionData.clientLocale}
            cst={props.cst}
            env={props.env}
            theme={props.theme}
            miniView={false}/>
          <Disclaimer lightMode={props.theme === 'Light'} appView={'TopSmartScore'}/>
        </TopNav>
      )
    case VIEW.TOP_ANALYST_STOCKS:
      return (
        <TopNav setView={setView} data-testid='top-analyst-stocks-full-view'>
          <Heading style={{ padding: '16px 0px 0px 0px' }}>
        Analyst Top Stocks
          </Heading>
          <ComponentHeader>{'Analysts’ Consensus By Sector'}</ComponentHeader>
          <AnalystConsensusBySector cst={props.cst} env={props.env} theme={props.theme}/>
          <ComponentHeader>{'Analysts’ Most Recommended Stocks'}</ComponentHeader>
          <TopAnalystStocks
            toggleMiniView={/* istanbul ignore next */ () => {
            }}
            locale={props.sessionData.clientLocale}
            cst={props.cst}
            env={props.env}
            theme={props.theme}
            miniView={false}/>
          <Disclaimer lightMode={props.theme === 'Light'} appView={'AnalystConsensus'}/>
        </TopNav>
      )
  }
}

function TopNav ({ children, setView }: { children: any, setView: (view: View) => void }) {
  return (
    <FullAppView>
      <BackButton data-testid='back-to-top-stocks'>
        <Link onClick={() => setView(VIEW.HOME)}>
          <LinkSpacing>
            <ArrowLeft/> <span>Back to TipRanks</span>
          </LinkSpacing>
        </Link>
      </BackButton>
      {children}
    </FullAppView>
  )
}

const Heading = styled.div`
  font-size: ${({ theme }) => theme.size.heading.h2.text.value};
  font-weight: ${({ theme }) => theme.font.heading.h2.weight.value};
  line-height: ${({ theme }) => theme.size.heading.h2.lineHeight.value};
  color: ${({ theme }) => theme.color.text.primary.value};
`

const FullAppView = styled.div`
  max-width: 925px;
  min-width: 310px;
  height: 100%;
  padding: 0 16px;
  color: ${({ theme }) => theme.color.text.primary.value};
`

const LinkSpacing = styled.div`
  display: flex;
  align-items: center;
`

const BackButton = styled.div`
  padding-top: 16px;
  height: 34px;
`

const ComponentHeader = styled.span`
  color: ${({ theme }) => theme.color.text.secondary.value};
  font-size: ${({ theme }) => theme.size.caption.medium.text.value};
  letter-spacing: 2px;
  text-transform: uppercase;
  display: flex;
  gap: 8px;
  padding: 16px 0 8px 0;
`
