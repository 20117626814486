import { useTheme } from 'styled-components'
import React from 'react'

export default function DropdownArrow (): JSX.Element {
  const theme = useTheme()
  const svgColour = theme.color.icons.primary.value
  return (
    <svg width="9" height="6" viewBox="0 0 9 6" fill={svgColour} xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.12785 5.09287C4.32654 5.31431 4.67346 5.31431 4.87215 5.09287L8.25175 1.32631C8.54061 1.00438 8.31213 0.492392 7.8796 0.492392H1.1204C0.687873 0.492392 0.459389 1.00438 0.748247 1.32631L4.12785 5.09287Z" fill={svgColour}/>
    </svg>
  )
}