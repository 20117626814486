import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { StyleProvider, ThemeSet } from 'ig-phoenix'
import React from 'react'
import TranslationLoader from './TranslationLoader'

interface ProviderProps {
  theme: 'Light' | 'Dark'
  clientLocale: string
  webSiteId?: string
  igCompany?: string
  supportedLanguages: string[]
  appName: string
  localeLoader: (locale: string) => Promise<any>
  children: JSX.Element
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    }
  }
})

export default function Providers ({ children, theme, clientLocale, webSiteId, igCompany, supportedLanguages, appName, localeLoader }: ProviderProps) {
  const themeSet = clientLocale === 'ja-JP' ? ThemeSet.IGWTPJapan : ThemeSet.IGWTPRebrand

  return (
    <QueryClientProvider client={queryClient}>
      <StyleProvider data-testid='style-provider' colorScheme={theme} themeSet={themeSet} withFonts={false}>
        <TranslationLoader
          clientLocale={clientLocale}
          webSiteId={webSiteId}
          igCompany={igCompany}
          supportedLanguages={supportedLanguages}
          appName={appName}
          localeLoader={localeLoader}
        >
          {children}
        </TranslationLoader>
      </StyleProvider>
    </QueryClientProvider>
  )
}
