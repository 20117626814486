import i18next, { i18n } from 'i18next'
import { initReactI18next } from 'react-i18next'

/**
 * Initialise our translation hook
 * @param {string} locale Client account's locale, like en-GB, fr-FR
 * @param {string[]} supportedLanguages an array of supported language by the app
 * @param {function} localeLoader function to load the translation file
 * @param {string} [webSiteId] for product specific translation
 * @param {string} [productTranslationFile] filename for webSiteId specific translation file
 */
export default async function initTranslation (
  locale: string,
  supportedLanguages: string[] = ['en-GB'],
  localeLoader: LocaleLoaderFunction,
  webSiteId?: string,
  productTranslationFile?: string
): Promise<i18n> {

  locale = supportedLanguages.find(ln => ln === locale.slice(0, 5)) ?? 'en-GB'

  return await Promise.allSettled(webSiteId && productTranslationFile ?
    [localeLoader(locale), localeLoader(productTranslationFile)]:
    [localeLoader(locale)]
  ).then(
    async (resolved) => {

      let firstTranslations, resources, ns
      const translation = resolved[0].status === 'fulfilled'? resolved[0].value.default : {}
      resources = {
        [locale]: {
          common: translation
        },
      }
      ns = ['common']

      // if two translation files are loaded then we configure two namespaces 'common' and 'translation'
      // 'translation' is the translation file searched first
      // 'common' is the translation file searched second as fallback
      if (resolved.length === 2 && webSiteId) {
        firstTranslations = resolved[1].status === 'fulfilled'? resolved[1].value.default : {}
        resources = {
          [locale]: {
            translation: firstTranslations,
            common: translation
          },
        }
        ns = ['translation', 'common']
      }

      const i18nInstance = i18next.createInstance()
      await i18nInstance
        .use(initReactI18next)
        .init({
          ns,
          fallbackNS: 'common',
          lng: locale,
          fallbackLng: locale,
          debug: false,
          resources,
          interpolation: {escapeValue : false},
          react: {
            useSuspense: false,
          }
        })

      return i18nInstance;
    }
  )
}

type LocaleLoaderFunction = (locale: string) => Promise<{ default: Record<string, string>}>;
