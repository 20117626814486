import { useTranslation } from '@ig-caa/i18n'
import { Link } from 'ig-phoenix'
import styled from 'styled-components'

export default function Disclaimer ({ lightMode, appView }: { lightMode: boolean, appView: 'TopSmartScore' | 'AnalystConsensus' | 'Home' }) {
  const { t } = useTranslation('translation')
  const learnMoreLink = `${t('disclaimer-link')}${t('disclaimer-link-suffix')}`

  if (appView === 'Home') {
    return (
      <Footer data-testid='top-stocks-ac-footer' lightMode={lightMode}>
        <Divider/>
        <FooterText>
          {t('ac-disclaimer')}
        </FooterText>
      </Footer>
    )
  }

  return (
    <Footer data-testid='top-stocks-ac-footer' lightMode={lightMode}>
      <Divider/>
      <FooterText>
        {appView === 'AnalystConsensus'
          ? 'Stocks with a \'Strong Buy\' rating consensus, according to the best performing analysts.'
          : 'Top Smart Score Stocks displays the best stocks according to the TipRanks Smart Score.'}
        <br/>
        <Link href={learnMoreLink} target='_blank' data-testid='ac-disclaimer-learn-more-link'>
          {'Learn more'}
        </Link>
        {appView === 'AnalystConsensus' ? ' about Analyst Top Stocks.' : ' about Top Smart Score Stocks.'}
        <Spacing/>
        {t('ac-disclaimer')}
      </FooterText>
    </Footer>
  )
}

const Spacing = styled.div`
  margin-bottom: 4px;
`

const FooterText = styled.div`
  font-size: ${({ theme }) => theme.size.caption.medium.text.value};
  line-height: ${({ theme }) => theme.size.caption.medium.lineHeight.value};
  color: ${({ theme }) => theme.color.text.secondary.value};
  margin-bottom: 4px;
`

const Footer = styled.div <{
  lightMode: boolean
}>`
  padding: 0 16px 8px 0;
  position: sticky;
  bottom: 0;
  background-color: ${({ theme, lightMode }) => lightMode ? theme.color.background.primarySurface.value : theme.color.background.primary.value};
  width: 100%;
  z-index: 12;
`

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.separator.secondary.background.value};
  width: 100%;
  padding: 0px 8px 8px 8px;
`
