import { useTheme } from 'styled-components'
import React from 'react'

export default function CloseSign (): JSX.Element {
  const theme = useTheme()
  const svgColour = theme.color.checkbox.active.icon.value
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.763305 3.74119L2.82474 6.85532L7.23673 0.190316C7.27186 0.137159 7.31759 0.0927465 7.37071 0.0601881C7.42383 0.0276297 7.48306 0.0077134 7.54425 0.0018338C7.60544 -0.00404581 7.66711 0.00425349 7.72493 0.0261507C7.78276 0.0480479 7.83534 0.0830134 7.87901 0.128598C7.95 0.20736 7.9925 0.309915 7.9991 0.418358C8.00569 0.526802 7.97597 0.634244 7.9151 0.721927L3.2532 7.76448C3.20502 7.8372 3.14091 7.89658 3.06631 7.93759C2.99172 7.97859 2.90884 8 2.82472 8C2.7406 8 2.65773 7.97859 2.58313 7.93759C2.50853 7.89658 2.44442 7.8372 2.39624 7.76448L1.24058 6.01866L0.0849265 4.27284C0.0240472 4.18515 -0.00569193 4.07769 0.000901262 3.96923C0.00749446 3.86077 0.0500013 3.7582 0.121 3.67942C0.16467 3.63384 0.217262 3.59888 0.275092 3.57698C0.332922 3.55509 0.394591 3.5468 0.45578 3.55268C0.516969 3.55856 0.576199 3.57848 0.62932 3.61105C0.682442 3.64361 0.728171 3.68803 0.763305 3.74119Z" fill={svgColour}/>
    </svg>
  )
}