import styled from 'styled-components'
import { Checkbox, CheckboxTick, DropdownArrow } from '@ig-caa/media'
import { useState, useRef } from 'react'
import { SectorState } from './AppProps'

interface FilterButtonProps {
  group: string
  categories: string[]
  handleFilter: (category: string) => void
  selectedStates: SectorState
}

export default function FilterButton (props: FilterButtonProps) {
  const { group, categories, handleFilter, selectedStates } = props
  const [isClicked, setIsClicked] = useState(false)

  const categoriesMenu = useRef(null)
  const filterButton = useRef(null)

  const closeCategoriesMenu = (e: any) => {
    // @ts-expect-error
    if (isClicked && !categoriesMenu.current?.contains(e.target) && !filterButton.current?.contains(e.target)) {
      setIsClicked(false)
    }
  }

  document.addEventListener('mousedown', closeCategoriesMenu)

  return (
    <>
      <FilterButtonContainer data-testid='filter-button-container' clicked={isClicked} onClick={() => setIsClicked(!isClicked)} ref={filterButton}>
        <FilterButtonText>{group}</FilterButtonText><DropdownArrow/>
      </FilterButtonContainer>
      {isClicked &&
        <CategoriesContainer data-testid='categories-container' ref={categoriesMenu}>
          {categories.map(category =>
            <Category data-testid={`${category}-dropdown-row`} key={category} onClick={() => handleFilter(category)}>
              {!selectedStates[category]
                ? <CategoryCheckbox
                    data-testid={`${category}-inactive-checkbox`}
                    type="checkbox"
                    id={`top-analyst-consensus-categories-${category}`}
                    name={category}
                    value={category}
                    defaultChecked={false}
                  />
                : <div id={category} data-testid={`${category}-active-checkbox`} style={{ width: '16px', height: '16px' }}>
                    <div style={{ position: 'absolute', width: '16px', height: '16px' }}><Checkbox /></div>
                    <div style={{ position: 'relative', width: '8px', height: '8px', top: '-4px', left: '4px' }}><CheckboxTick /></div>
                  </div> }
              <CategoryText htmlFor={category}>{category}</CategoryText>
            </Category>)}
        </CategoriesContainer>
      }
    </>
  )
}

const FilterButtonContainer = styled.div<{
  clicked: boolean
}>`
  width: 48px;
  height: 16px;
  padding: 4px 8px 4px 8px;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.color.separator.secondary.background.value};
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ theme, clicked }) => clicked ? theme.color.separator.primary.background.value : theme.color.dropdown.background.value};
  box-sizing: content-box;
  cursor: pointer;
`

const FilterButtonText = styled.div`
  font-size: ${({ theme }) => theme.size.caption.medium.text.value};
  line-height: ${({ theme }) => theme.size.caption.medium.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.caption.weight.value};
  color: ${({ theme }) => theme.color.text.primary.value};
`

const CategoriesContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.color.dropdown.border.value};
  border-radius: 2px;
  box-shadow: 0px 2px 10px 0px #00295426;
  background: ${({ theme }) => theme.color.background.primarySurface.value};
  position: absolute;
  z-index: 11;
  margin-top: 4px;
`

const Category = styled.div`
  padding: 4px 16px 4px 8px;
  gap: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.color.separator.primary.background.value};
  }
`

const CategoryCheckbox = styled.input`
  height: 16px;
  width: 16px;
  border: 1px solid ${({ theme }) => theme.color.dropdown.border.value};
  border-radius: 2px;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex !important;
  margin: 0px;
  cursor: pointer;
`

const CategoryText = styled.label`
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  line-height: ${({ theme }) => theme.size.body.small.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.body.weight.value};
  color: ${({ theme }) => theme.color.text.primary.value};
  margin-bottom: 0px;
  cursor: pointer;
`
