import { useTheme } from 'styled-components'
import React from 'react'

export default function CloseSign (): JSX.Element {
  const theme = useTheme()
  const svgColour = theme.color.search.closeIcon.value
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill={svgColour} xmlns="http://www.w3.org/2000/svg">
      <path d="M9.44626 1.21113C9.64153 1.01587 9.64153 0.699282 9.44626 0.504017C9.251 0.308756 8.93442 0.308751 8.73916 0.504005L4.94967 4.29326L1.06045 0.403973C0.865147 0.208665 0.548472 0.208727 0.353245 0.404113C0.15813 0.599385 0.158194 0.915837 0.353388 1.11103L4.24285 5.00049L0.453455 8.7896C0.258148 8.98489 0.258143 9.30154 0.453443 9.49684C0.648737 9.69213 0.965372 9.69213 1.16067 9.49684L4.94993 5.70757L8.83873 9.5966C9.03399 9.79187 9.35057 9.79187 9.54584 9.59661C9.7411 9.40135 9.7411 9.08477 9.54585 8.88951L5.65695 5.00045L9.44626 1.21113Z" fill={svgColour}/>
    </svg>
  )
}