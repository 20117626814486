
import { I18nextProvider, initTranslation } from '@ig-caa/i18n'
import { useQuery } from '@tanstack/react-query'
import { Spinner } from 'ig-phoenix'
import React from 'react'

interface TranslationLoaderProps {
  clientLocale: string
  webSiteId?: string
  igCompany?: string
  supportedLanguages: string[]
  appName: string
  localeLoader: (locale: string) => Promise<any>
  children: JSX.Element
}

export default function TranslationLoader({ clientLocale, webSiteId, igCompany, supportedLanguages, appName, localeLoader, children }: TranslationLoaderProps) {
  
  const loadTranslationsFn = async () => {
    if (webSiteId && igCompany) {
      let productTranslationFile = `${clientLocale}-${webSiteId}`
      if (igCompany === 'igza') productTranslationFile = `en-ZA-${webSiteId}`
      return await initTranslation(
        clientLocale,
        supportedLanguages,
        localeLoader,
        webSiteId,
        productTranslationFile
      )
    }

    return await initTranslation(
      clientLocale,
      supportedLanguages,
      localeLoader
    )
  }

  const { isLoading, isError, error, data: i18n } = useQuery([`${appName}LoadTranslations`, clientLocale], loadTranslationsFn)

  if (isError) return <div>An error has occurred: {(error as Error)?.message}</div>
  if (isLoading) return <div className={`${appName}-loading`} data-testid='isLoading'><Spinner radius='40px'/></div>
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}
