import { useTheme } from 'styled-components'
import React from 'react'

export default function ArrowLeft (): JSX.Element {
  const theme = useTheme()
  const svgColour = theme.color.text.link.value
  return (
    <svg width="20" height="20" viewBox="3 0 19 19" fill={svgColour} xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.59185 4.61427C7.70322 4.50277 7.85433 4.44007 8.01192 4.43998C8.16952 4.43988 8.3207 4.5024 8.43221 4.61377C8.54372 4.72514 8.60641 4.87625 8.60651 5.03385C8.6066 5.19145 8.54409 5.34263 8.43272 5.45413L4.98273 8.89993L15.4346 8.89993C15.592 8.89993 15.7431 8.96249 15.8544 9.07384C15.9658 9.18519 16.0283 9.33621 16.0283 9.49368C16.0283 9.65115 15.9658 9.80218 15.8544 9.91353C15.7431 10.0249 15.592 10.0874 15.4346 10.0874L4.99279 10.0874L8.43272 13.5232C8.48786 13.5784 8.53159 13.6439 8.56141 13.7161C8.59123 13.7882 8.60655 13.8654 8.60651 13.9435C8.60646 14.0215 8.59104 14.0988 8.56114 14.1709C8.53123 14.2429 8.48742 14.3084 8.43221 14.3636C8.377 14.4187 8.31146 14.4624 8.23935 14.4923C8.16724 14.5221 8.08996 14.5374 8.01192 14.5374C7.93389 14.5373 7.85663 14.5219 7.78455 14.492C7.71247 14.4621 7.64699 14.4183 7.59185 14.3631L3.13207 9.90859C3.07685 9.85349 3.03304 9.78803 3.00315 9.71597C2.97326 9.64391 2.95787 9.56666 2.95787 9.48865C2.95787 9.41064 2.97326 9.33339 3.00315 9.26133C3.03304 9.18927 3.07685 9.12381 3.13207 9.0687L7.59185 4.61427Z"/>
    </svg>
  )
}
