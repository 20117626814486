import { useQuery } from '@tanstack/react-query'
import { Spinner } from 'ig-phoenix'
import styled, { DefaultTheme, useTheme } from 'styled-components'
import { MenuArrowDown, MenuArrowUp } from '@ig-caa/media'
import { ENVIRONMENTS, SectorConsensusData, Theme } from './AppProps'
import fetchSectorConsensus from '../utils/fetchSectorConsensus'
import { useState } from 'react'

const holdBarColour = {
  Dark: '#8292A6',
  Light: '#CDDDDE'
}

interface SectorState {
  [key: string]: boolean
}

export default function AnalystConsensusBySector ({ cst, env, theme }: { cst: string, env: keyof typeof ENVIRONMENTS, theme: Theme }) {
  const { isLoading, isError, data } = useQuery<SectorConsensusData[], any>({
    queryKey: ['sectorConsensus'],
    queryFn: async () => await fetchSectorConsensus(env, cst),
    refetchOnWindowFocus: false
  })
  const [showDetails, setShowDetails] = useState<SectorState>({})
  const handleToggleDetails = (sector: string) => {
    setShowDetails((prevState) => ({
      ...prevState,
      [sector]: !prevState[sector] || false
    }))
  }
  const styledTheme = useTheme()

  if (isLoading) {
    return <div className="top-stocks-loading" data-testid='isLoading'><Spinner radius='40px' /></div>
  }

  if (isError) {
    return <ErrorText data-testid="analyst-stocks-error">We&apos;re not able to load this data right now.<br/>Please come back later.</ErrorText>
  }

  return (
    <ChartContainer data-testid='analyst-consensus-chart-container'>
      <div data-testid='analyst-consensus-chart'>
        {data.map(item => {
          const numOfConsensus = item.strongBuy + item.moderateBuy + item.neutral + item.moderateSell + item.strongSell
          return (
            <>
              <BarContainer key={item.sector}>
                <Sector>{item.sector}</Sector>
                <Bar>
                  <ColouredBar width={item.strongBuy / numOfConsensus * 100} color={styledTheme.color.activityBar.active.buy.value} opacity={1}/>
                  <ColouredBar width={item.moderateBuy / numOfConsensus * 100} color={styledTheme.color.activityBar.active.buy.value} opacity={0.6}/>
                  <ColouredBar width={item.neutral / numOfConsensus * 100} color={holdBarColour[theme]} opacity={1}/>
                  <ColouredBar width={item.moderateSell / numOfConsensus * 100} color={styledTheme.color.activityBar.active.sell.value} opacity={0.6}/>
                  <ColouredBar width={item.strongSell / numOfConsensus * 100} color={styledTheme.color.activityBar.active.sell.value} opacity={1}/>
                </Bar>
                <Arrow data-testid={`show-sector-details-${item.sector.replace(/\s/g, '')}`} onClick={() => {
                  handleToggleDetails(item.sector)
                }}>
                  {!showDetails[item.sector] && <MenuArrowDown/>}
                  {showDetails[item.sector] && <MenuArrowUp/>}
                </Arrow>
              </BarContainer>
              {showDetails[item.sector] &&
                <DetailsContainer>
                  <SumContainer data-testid={`${item.sector}-sum`}>
                    <Sum>{numOfConsensus}</Sum><Stocks>stocks</Stocks>
                  </SumContainer>
                  <LabelsContainer>
                    <ConsensusLabels theme={theme} styledTheme={styledTheme} item={item}/>
                  </LabelsContainer>
                </DetailsContainer>
              }
            </>
          )
        })
        }
        <Legend>
          <ConsensusLabels theme={theme} styledTheme={styledTheme}/>
        </Legend>
      </div>
    </ChartContainer>
  )
}

const ConsensusLabels = ({ theme, styledTheme, item }: { theme: Theme, styledTheme: DefaultTheme, item?: SectorConsensusData }) => (
  <>
    <SquareLabelContainer>
      <ColouredSquare color={styledTheme.color.activityBar.active.buy.value} opacity={1}/>
      <SingleStockDetails>{item?.strongBuy} Strong Buy</SingleStockDetails>
    </SquareLabelContainer>
    <SquareLabelContainer>
      <ColouredSquare color={styledTheme.color.activityBar.active.buy.value} opacity={0.6}/>
      <SingleStockDetails>{item?.moderateBuy} Moderate Buy</SingleStockDetails>
    </SquareLabelContainer>
    <SquareLabelContainer>
      <ColouredSquare color={holdBarColour[theme]} opacity={1}/>
      <SingleStockDetails>{item?.neutral} Hold</SingleStockDetails>
    </SquareLabelContainer>
    <SquareLabelContainer>
      <ColouredSquare color={styledTheme.color.activityBar.active.sell.value} opacity={0.6}/>
      <SingleStockDetails>{item?.moderateSell} Moderate Sell</SingleStockDetails>
    </SquareLabelContainer>
    <SquareLabelContainer>
      <ColouredSquare color={styledTheme.color.activityBar.active.sell.value} opacity={1}/>
      <SingleStockDetails>{item?.strongSell} Strong Sell</SingleStockDetails>
    </SquareLabelContainer>
  </>
)

const LabelsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
`

const Legend = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  min-width: 300px;
`

const SquareLabelContainer = styled.div`
  display: flex;
  align-items: center;
`

const ErrorText = styled.div`
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  color: ${({ theme }) => theme.color.text.secondary.value};
  text-align: center;
  line-height: 16px;
`

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`

const BarContainer = styled.div`
  display: flex;
  margin-bottom: 4px;
  padding-bottom: 4px;
  font-size: 13px;
  position: relative;
  align-items: center;
`

const Sector = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 112px;
  padding-right: 8px;
`

const Bar = styled.div`
  display: flex;
  width: 100%;
  padding-right: 8px;
  min-width: 148px;
`

const Arrow = styled.div`
  width: 10px;
  height: 10px;
  display: flex;
  cursor: pointer;
`

const DetailsContainer = styled.div`
  display: flex;
  padding-bottom: 16px;
  margin-top: -8px;
  font-size: 13px;
`

const SumContainer = styled.div`
  display: flex;
  align-items: baseline;
  padding-right: 8px;
  min-width: 110px;
`

const ColouredBar = styled.span<{
  width: number
  color: string
  opacity: number
}>`
  width: ${({ width }) => width}%;
  height: 12px;
  background-color: ${({ color }) => color};
  opacity: ${({ opacity }) => opacity};
`

const Sum = styled.span`
  font-size: 18px;
  font-weight: 600;
  padding-right: 2px;
`

const Stocks = styled.span`
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.text.secondary.value};
`

const ColouredSquare = styled.span<{
  color: string
  opacity: number
}>`
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
  opacity: ${({ opacity }) => opacity};
  border-radius: 2px;
`

const SingleStockDetails = styled.span`
  font-size: 13px;
  font-weight: 400;
  padding-left: 4px;
  margin-right: 16px;
`
