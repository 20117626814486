import { useTheme } from 'styled-components'
import React from 'react'

export default function MenuArrowUp (): JSX.Element {
  const theme = useTheme()
  const svgColour = theme.color.icons.secondary.value
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill={svgColour} xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.5848 2.73434C4.63933 2.68128 4.70409 2.63918 4.77537 2.61047C4.84665 2.58175 4.92306 2.56696 5.00022 2.56696C5.07738 2.56696 5.15379 2.58175 5.22507 2.61047C5.29635 2.63918 5.36111 2.68128 5.41564 2.73434L9.82225 7.01919C9.87801 7.07195 9.92244 7.13495 9.95296 7.20453C9.98347 7.27412 9.99945 7.34889 9.99999 7.42453C10.0005 7.50017 9.98559 7.57515 9.95606 7.64513C9.92653 7.71511 9.88299 7.7787 9.82797 7.83219C9.77295 7.88569 9.70754 7.92802 9.63554 7.95675C9.56355 7.98548 9.4864 8.00002 9.40857 7.99953C9.33075 7.99904 9.2538 7.98354 9.18219 7.95391C9.11058 7.92428 9.04574 7.88113 8.99144 7.82695L5.00025 3.94608L1.00906 7.82695C0.954779 7.88122 0.889935 7.92447 0.8183 7.95418C0.746666 7.98388 0.669671 7.99945 0.59179 7.99999C0.513909 8.00052 0.436697 7.986 0.36464 7.95727C0.292584 7.92854 0.227122 7.88618 0.172059 7.83265C0.116997 7.77912 0.073432 7.71549 0.0438967 7.64545C0.0143623 7.57541 -0.000553132 7.50037 1.81184e-05 7.42468C0.000589369 7.34899 0.0166359 7.27416 0.047224 7.20455C0.0778131 7.13494 0.122334 7.07193 0.178198 7.01919L4.5848 2.73434Z" fill="#5A5E5F"/>
    </svg>

  )
}
